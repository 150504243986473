<template>
  <div
    class="toast fade popup position-fixed text-start toast-wide"
    aria-live="assertive"
    aria-atomic="true"
    ref="modal"
    :style="{ top: positionY + 'px', left: positionX + 'px' }"
  >
    <div class="toast-header">
      <strong class="me-auto">
        {{
          $t(`price.edit`, {
            expr: countries !== undefined ? "" : modelValueData.currency,
          })
        }}
      </strong>
      <button
        type="button"
        class="btn-close"
        v-on:click="closeModal(false)"
        v-on:mouseleave="focusOut = true"
        v-on:mouseenter="focusOut = false"
        :aria-label="$t(`orderChange.close`)"
      ></button>
    </div>
    <div class="toast-body" ref="body">
      <div class="row custom-input whitebg-input mb-1">
        <div class="col-12">
          <label class="form-label bold-12 text-gray" v-if="vatMode === 1">
            {{ $t("price.netPrice") }}
          </label>
          <label class="form-label bold-12 text-gray" v-else-if="vatMode === 2">
            {{ $t("price.grossPrice") }}
          </label>
          <div class="fix-end-input">
            <input
              type="number"
              class="form-control"
              v-model="modelValueData.amount"
              ref="imp"
              step="0.01"
              v-on:focusout="closeModal(true)"
            />
            <select
              v-model="modelValueData.currency"
              v-if="currencies !== undefined"
            >
              <option
                :value="value.code"
                :key="value"
                v-for="value in currencies"
              >
                {{ value.code }}
              </option>
            </select>
            <span v-else>
              {{ modelValueData.currency }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="row my-2 mb-4 text-gray"
        v-if="modelValueData.amount !== undefined"
      >
        <div class="col-3">
          {{ modelValueData.amount }} {{ modelValueData.currency }} <b>=</b>
        </div>
        <div
          class="col-auto"
          v-for="c in currencies
            .filter((e) => e.code === modelValueData.currency)[0]
            .exchange_rates.filter(
              (e) => e.converted_currency_code != modelValueData.currency
            )"
          :key="c.converted_currency_code"
        >
          {{
            (modelValueData.amount * c.rate).toLocaleString("hu-HU", {
              style: "currency",
              currency: c.converted_currency_code,
              minimumFractionDigits: 0,
              maximumFractionDigits:
                c.converted_currency_code === "HUF"
                  ? 0
                  : c.converted_currency_code === "BTC"
                  ? 6
                  : 2,
            })
          }}
        </div>
      </div>
      <div class="row" v-if="countries.length > 0">
        <label class="form-label bold-12 text-gray" v-if="vatMode === 1">
          {{ $t("price.grossPrices") }}
        </label>
        <label class="form-label bold-12 text-gray" v-else-if="vatMode === 2">
          {{ $t("price.netPrices") }}
        </label>
      </div>
      <div
        class="row align-items-center custom-input whitebg-input mb-1"
        v-for="country in countries"
        :key="country.code"
      >
        <div class="col-5">
          <b>{{ country.name }}</b>
        </div>
        <div class="col-7">
          <div class="fix-end-input">
            <input
              type="number"
              class="form-control"
              v-model="vatPrice[country.id]"
              step="0.01"
              v-on:input="calcBasePrice(country)"
              v-on:focusout="closeModal(true)"
            />
            <select
              v-model="modelValueData.currency"
              v-if="currencies !== undefined"
            >
              <option
                :value="value.code"
                :key="value"
                v-for="value in currencies"
              >
                {{ value.code }}
              </option>
            </select>
            <span v-else>
              {{ modelValueData.currency }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

const bootstrap = require("bootstrap");

export default {
  name: "Price",
  props: {
    modelValue: Object,
    show: Boolean,
    positionY: Number,
    positionX: Number,
  },
  emits: ["update:modelValue", "update:show", "change"],
  data: function () {
    return {
      vatMode: 2,
      modelValueData:
        typeof this.modelValue === "object"
          ? Object.assign({}, this.modelValue)
          : {},
      vatPrice: {},
      focusOut: false,
    };
  },
  mounted() {
    let myModal = new bootstrap.Toast(this.$refs.modal, { autohide: false });
    if (this.show) {
      myModal.show();
      this.$refs.imp.focus();
    } else {
      myModal.hide();
    }
    this.calcVatPrice();
  },
  computed: {
    currencies() {
      const store = useStore();
      return store.state.currencies;
    },
    countries() {
      const store = useStore();
      return store.state.countries.filter(
        (e) => e.default_currency === this.modelValueData.currency
      );
    },
  },
  methods: {
    closeModal(checkFocus = false) {
      if (checkFocus) {
        if (this.$refs.body.contains(document.activeElement)) {
          return;
        }
        const _this = this;
        setTimeout(function () {
          if (
            _this.$refs.body.contains(document.activeElement) ||
            !_this.focusOut
          ) {
            return;
          }
          _this.$emit("update:modelValue", _this.modelValueData);
          _this.$emit("change");
          _this.$emit("update:show", false);
        }, 400);
      } else {
        this.$emit("update:modelValue", this.modelValueData);
        this.$emit("change");
        this.$emit("update:show", false);
      }
    },
    calcVatPrice() {
      for (const country of this.countries) {
        let rate = country.vatRates.filter((e) => e.default);
        if (rate.length > 0) {
          rate = rate[0];
          if (this.vatMode === 2) {
            this.vatPrice[country.id] =
              Math.round(
                (this.modelValueData.amount / (1 + rate.rate / 100)) * 100
              ) / 100;
          } else {
            this.vatPrice[country.id] =
              Math.round(
                this.modelValueData.amount * (1 + rate.rate / 100) * 100
              ) / 100;
          }
        }
      }
    },
    calcBasePrice(country) {
      let rate = country.vatRates.filter((e) => e.default);
      if (rate.length > 0) {
        rate = rate[0];
        if (this.vatMode === 2) {
          this.modelValueData.amount =
            Math.round(
              this.vatPrice[country.id] * (1 + rate.rate / 100) * 100
            ) / 100;
        } else {
          this.modelValueData.amount =
            Math.round(
              (this.vatPrice[country.id] / (1 + rate.rate / 100)) * 100
            ) / 100;
        }
      }
    },
  },
  watch: {
    modelValueData: {
      deep: true,
      handler() {
        //this.$emit("update:modelValue", this.value);
        this.calcVatPrice();
      },
    },
    show() {
      let myModal = new bootstrap.Toast(this.$refs.modal, { autohide: false });
      if (this.show) {
        myModal.show();
        this.$refs.imp.focus();
      } else {
        myModal.hide();
      }
    },
  },
  components: {},
};
</script>
